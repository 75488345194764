// extracted by mini-css-extract-plugin
export var active = "scrollMenu-module--active--e6993";
export var container = "scrollMenu-module--container--c8405";
export var linkContainer = "scrollMenu-module--linkContainer--78dbe";
export var navBtn = "scrollMenu-module--navBtn--db85b";
export var navHidden = "scrollMenu-module--navHidden--ad93f";
export var navNext = "scrollMenu-module--navNext--99639";
export var navPrev = "scrollMenu-module--navPrev--a2cde";
export var tabItem = "scrollMenu-module--tabItem--ecab2";
export var tabItemCount = "scrollMenu-module--tabItemCount--d4e98";
export var tabItemWCount = "scrollMenu-module--tabItemWCount--22114";