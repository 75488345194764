import React, { useState, useEffect, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ScrollMenu from "../../ScrollMenu"

const Tabs = ({ currentTab }) => {
  const [tabs, updateTabs] = useState([])
  const [activeTab, updateActiveTab] = useState("")

  const {
    allWpTag: { nodes: tabNames, sum },
  } = useStaticQuery(
    graphql`
      query {
        allWpTag(
          filter: {
            vacancies: { nodes: { elemMatch: { status: { eq: "publish" } } } }
          }
        ) {
          nodes {
            name
            count
          }
          sum(field: count)
        }
      }
    `
  )

  const createTabs = useCallback(() => {
    if (tabNames?.length) {
      updateTabs([
        { name: "Все", anchor: "jobs", count: sum },
        ...tabNames.map(({ name, count }) => ({ name, count, anchor: "jobs" })),
      ])
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    createTabs()
  }, [tabNames]) // eslint-disable-line

  useEffect(() => {
    updateActiveTab(currentTab)
  }, [currentTab])

  return tabs.length > 0 ? (
    <ScrollMenu
      tabs={tabs}
      pageLink="vacancies"
      activeTab={activeTab}
      showCount
      changeState
    />
  ) : null
}

export default Tabs
