import React, { useState, useEffect, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames"
import Svg from "react-inlinesvg"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import Tabs from "./Tabs"

import { ArrowIcon } from "../../assets/images"
import * as styles from "./JobsTabs.module.css"

const JobsTabs = ({ location }) => {
  const { t } = useTranslation()
  const [activeTab, updateActiveTab] = useState("")
  const [jobs, updateJobs] = useState([])

  const {
    allWpVacancy: { nodes: vacancies },
  } = useStaticQuery(
    graphql`
      query {
        allWpVacancy(
          filter: { status: { eq: "publish" } }
          sort: { order: ASC, fields: menuOrder }
        ) {
          nodes {
            title
            content
            id
            link
            tags {
              nodes {
                name
              }
            }
          }
        }
      }
    `
  )

  const onChangeActiveTab = useCallback(tab => {
    updateActiveTab(tab || "Все")
    if (tab === "Все") {
      updateJobs(vacancies.map(({ title, link }) => ({ title, link })))
    } else {
      let currentJobs = vacancies.filter(vacancy =>
        vacancy?.tags?.nodes.some(tag => tag.name === tab)
      )
      updateJobs(currentJobs.map(({ title, link }) => ({ title, link })))
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    onChangeActiveTab(location?.state?.tab || "Все")
  }, [vacancies, location]) // eslint-disable-line

  const JobItem = ({ job }) => {
    return (
      <Link
        className={styles.jobItem}
        to={job.link.replace("/vacancy/", "/vacancies/")}
        state={{ tab: activeTab }}
        aria-hidden="true"
      >
        {job.title}
        <span className={styles.jobArrow}>
          <Svg src={ArrowIcon} />
        </span>
      </Link>
    )
  }

  return (
    <div className={cx("page-block-container", styles.container)}>
      <a id="jobs" href="/#">
        {""}
      </a>
      <Tabs currentTab={activeTab} />
      <div className={styles.jobsList}>
        {jobs.length > 0 ? (
          jobs.map((item, index) => <JobItem key={index} job={item} />)
        ) : (
          <div>{t("Вакансии на HeadHunter")}</div>
        )}
      </div>
    </div>
  )
}

export default JobsTabs
