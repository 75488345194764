import React, { useContext } from "react"
import { Link } from "gatsby-plugin-react-i18next"
import cx from "classnames"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"

import * as styles from "./scrollMenu.module.css"

const Index = ({
  tabs,
  pageLink,
  activeTab,
  changeState = false,
  currentHash,
  showCount = false,
  hideArrows = false,
}) => {
  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext)

    return (
      <div
        className={cx(
          styles.navBtn,
          styles.navPrev,
          isFirstItemVisible && styles.navHidden
        )}
        onClick={() => scrollPrev()}
        aria-hidden="true"
      />
    )
  }

  const RightArrow = () => {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

    return (
      <div
        className={cx(
          styles.navBtn,
          styles.navNext,
          isLastItemVisible && styles.navHidden
        )}
        onClick={() => scrollNext()}
        aria-hidden="true"
      />
    )
  }

  return (
    <div className={styles.container}>
      <ScrollMenu
        RightArrow={!hideArrows ? RightArrow : ""}
        LeftArrow={!hideArrows ? LeftArrow : ""}
      >
        {tabs.map((item, index) => (
          <div
            className={styles.linkContainer}
            itemID={`element-${index}`}
            key={`ScrollMenu_${index}_${item.name}`}
          >
            <Link
              className={cx(
                styles.tabItem,
                showCount && styles.tabItemWCount,
                (activeTab === item.name ||
                  currentHash === `#${item.anchor}`) &&
                  styles.active
              )}
              key={index}
              aria-hidden="true"
              to={`/${pageLink}/#${item.anchor}`}
              state={changeState && { tab: item.name }}
            >
              {item.name}
              {showCount && (
                <span className={styles.tabItemCount}>{item?.count || 0}</span>
              )}
            </Link>
          </div>
        ))}
      </ScrollMenu>
    </div>
  )
}

export default Index
